import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  LOAD_INIT_QUESTION,
  LOAD_NEXT_QUESTION,
  LOAD_CATEGORIES,
  QUERY_USERPROFILE
} from "../graphQL/queries";
import { ERROR_HANDLE, SEND_EMAIL, SUBMIT_QUESTION } from "../graphQL/mutations";
import { useSelector, useDispatch } from "react-redux";
// import { Notifications } from 'react-push-notification';
import { ToastContainer, toast } from "react-toastify";
import * as Actions from "../store/actions";
import { Steps } from "rsuite";
import NestedNode from "./nestedQue";
import ProgressBar from "@ramonak/react-progress-bar";
import thankyou from "../asset/frames/thankyou.png";
import errorimg from "../asset/frames/error.png";
import { v4 as uuidv4 } from 'uuid';
import travelsment from "../asset/frames/travelsment.png";

const TravelQuestions = (props: any) => {
  const { Questions, Summary, ParamLocation, RefCode } = useSelector(
    (state: any) => state.Question
  );

  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const [flagQue, setFlagQue] = useState(false);
  const [vertical, setVertical] = useState(false);
  const [dateFlexFlag, setDateFlexFlag] = useState(false);
  const [flexibleDate, setFlexibleDate] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [currentStep, setCurrentStep] = useState(-1);
  const [nextNodeID, setNextNodeID] = useState("");
  const [adultsCount, setAdultsCount] = useState(2);
  const [childrensCount, setChildrensCount] = useState(0);
  const [childrensDetails, setChildrensDetails] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [showSummery, setShowSummery] = useState(false);
  const [submitAPIFlag, setSubmitAPIFlag] = useState(false);
  const [readyToShowSummery, setReadyToShowSummery] = useState(false);
  const [airSelectedStatus, setAirSelectedStatus] = useState(false);
  const [totalDaysSelected, setTotalDaysSelected] = useState(1);
  const [category, setCategory] = useState([]);
  const [toggleHover, setToggleHover] = useState(false);
  const [success, setSuccess] = useState(false);
  const [refUserProfile, setRefUserProfile] = useState<any>();
  const [progressBar, setProgressBar] = useState<any>(7.142857143);
  //const [queryUserProfile, { refetch: UserProfileRefetch }] = useLazyQuery(QUERY_USERPROFILE);

  const query = window.location.search.length > 0 ?
    window.location.search.substring(1).split('&').filter((f => !f.startsWith("refCode=") && !f.startsWith("dev="))).join('&') : ""; // ignore ref
  const [questiontype, setQuestionTypeX] = useState('BeachVacation')
  // var questiontype = query.split("=")[1];
  var summary: string;
  // if (questiontype?.length > 0) {
  //   questiontype = questiontype?.split("&")[0];
  // }

  const [Email] = useMutation(SEND_EMAIL, {


    onError: (error) => {
    }
  })
  const monthInWords = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  var endDate = new Date();
  // endDate.setDate(endDate.getDate() + 4);
  const [selectionRange, setSelectionRange] = useState({
    startDate: endDate,
    endDate: endDate,
    key: "selection",
  });
  const currentMonth: string = monthInWords[endDate.getMonth()] + "," + endDate.getFullYear()
  const presentDay = currentMonth.split(",")
  const pM = presentDay[0]
  const pY = parseInt(presentDay[1])
  let day = '30';
  let cruiseStartDate = '';
  let cruiseEndDate = '';
  const date = Summary.desiredCuriseMonth.value ? Summary.desiredCuriseMonth.value.split("-") : null;
  if (date) {
    const sM = date[0].split(",")[0]
    const sY = parseInt(date[0].split(",")[1])
    const eM = date[1].split(",")[0]
    const eY = parseInt(date[1].split(",")[1])

    if (eM === 'jan' || eM === 'mar' || eM === 'may' || eM === 'Jul' || eM === 'aug' || eM === 'oct' || eM === 'dec') {
      day = '31';
    } else if (eM === 'feb') {

      if (((eY % 4) == 0) && ((eY % 100) != 0) || (eY % 400) == 0) {
        day = '29';
      } else {
        day = '28';
      }
    }
    cruiseStartDate = `1 ${sM} ${sY}`
    cruiseEndDate = `${day} ${eM} ${eY}`

  }
  else {
    if (pM === 'jan' || pM === 'mar' || pM === 'may' || pM === 'Jul' || pM === 'aug' || pM === 'oct' || pM === 'dec') {
      day = '31';
    } else if (pM === 'feb') {

      if (((pY % 4) == 0 && (pY % 100) != 0) || (pY % 400) == 0) {
        day = '29';
      } else {
        day = '28';
      }
    }

    cruiseStartDate = `1 ${monthInWords[endDate.getMonth()]} ${endDate.getFullYear()}`
    cruiseEndDate = `${day} ${monthInWords[endDate.getMonth()]} ${endDate.getFullYear()}`
  }
  const [roomArraangement, setRoomArraangement]: any = useState([
    {
      room_no: 0,
      adults: 2,
      childrens: 0,
      childOneAge: -1,
      childTwoAge: -1,
      childThreeAge: -1,
      childFourAge: -1,
    },
  ]);

  const [ageGroup, setAgeGroup] = useState([
    "Under 1",
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
  ]);

  const SendEmail = () => {
    Email({
      variables: {
        to: Summary.email.value,
        subject: `New Vacation Request ${Summary.name.value} - ${Summary.destination.value}`,
        body: `<p>Hi ${Summary.name.value}</p>
            <p> We received your request, here is the summary of what was submitted </p>
            <br />
            <p>${summary}</p>
            <br />
            <p>If you have any questions please let us know. </p>
            <br />
            <p>Best Regards, <br />
             Travelsment </p>`,
        submitTime: new Date().getTime(),
      }
    })
  }
  const [displayActiveId, setDisplayActiveId] = useState("");
  const { data: Category } = useQuery(LOAD_CATEGORIES);
  console.log(flagQue, questiontype, 'lamoraaa');


  const setQuestionType = (type: string) => {
    setQuestionTypeX(type);
    setFlagQue(false);
    setNextNodeID("");
  }

  const [queryInitQuestion] = useLazyQuery(LOAD_INIT_QUESTION);
  const [queryNextQuestion] = useLazyQuery(LOAD_NEXT_QUESTION);

  // const { data: initData, loading } = useQuery(
  //   flagQue ? LOAD_NEXT_QUESTION : LOAD_INIT_QUESTION,
  //   {
  //     variables: {
  //       id: nextNodeID,
  //       eq: flagQue ? undefined : questiontype ? questiontype : "BeachVacation"
  //     },
  //   }
  // );

  const [initData, setInitData] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);

  useEffect(() => {
    debugger;
    setLoading(true);
    if (flagQue) {
      queryNextQuestion({
        variables: {
          id: nextNodeID,
          eq: flagQue ? undefined : questiontype ? questiontype : "BeachVacation"
        } 
      }).then((res) => {
        setInitData({ ...res.data });
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        console.log("Error fetching next question", error);
        toast.error("Error fetching next question");
      });
    }
    else {
      queryInitQuestion({
        variables: {
          id: nextNodeID,
          eq: questiontype ? questiontype : "BeachVacation"
        }
      }).then((res) => {
        setInitData({ ...res.data });
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        console.log("Error fetching next question", error);
        toast.error("Error fetching next question");
      });
    } 
  }, [questiontype, flagQue, nextNodeID]);

  // const { data: initData, loading } = NextQue(nextNodeID);
  // const findNextQue = async (id: any) => {
  //     const data = await NextQue(id);
  //     updateDOM(data);
  // }
  // const Destination = new URLSearchParams(props.location.search).get("loc");
  // console.log("Destination",Destination);
  const [errorHandle] = useMutation(ERROR_HANDLE);
  //const [addUserProfile] = useMutation(ADD_USERPROFILE);
  //const [updateUserProfile] = useMutation(UPDATE_USERPROFILE);
  const [updateData, { error }] = useMutation(SUBMIT_QUESTION, {
    onError: (error) => {
      debugger;
      var ChildrenAges = "";
      if (childrensCount > 0) {
        for (var i = 0; i < roomArraangement.length; i++) {
          if (roomArraangement[i].childOneAge >= 0) {
            if (ChildrenAges !== "") {
              ChildrenAges += ", ";
            }
            ChildrenAges += roomArraangement[i].childOneAge.toString();
            // console.log("ChildrenAges 1111111",roomArraangement[i].childOneAge,ChildrenAges);
          }
          // console.log("ChildrenAges",roomArraangement[i].childTwoAge, ChildrenAges);
          if (roomArraangement[i].childTwoAge >= 0) {
            if (ChildrenAges !== "") {
              ChildrenAges += ", ";
            }
            ChildrenAges += roomArraangement[i].childTwoAge.toString();
          }
          // console.log("ChildrenAges",roomArraangement[i].childThreeAge, ChildrenAges);
          if (roomArraangement[i].childThreeAge >= 0) {
            if (ChildrenAges !== "") {
              ChildrenAges += ", ";
            }
            ChildrenAges += roomArraangement[i].childThreeAge.toString();
          }
          if (roomArraangement[i].childFourAge >= 0) {
            if (ChildrenAges !== "") {
              ChildrenAges += ", ";
            }
            ChildrenAges += roomArraangement[i].childThreeAge.toString();
          }
          // console.log("ChildrenAges",ChildrenAges);
        }
      }
      console.log("mutationData", error); // the error if that is the case
      summary = `${Summary.name.value ? Summary.name.value : ""
        }, You are going to the ${Summary.destination.value ? Summary.destination.value : ""
        }
            from ${Summary.from.value ? Summary.from.value : ""
        }, during the dates from ${Summary.startEndDate.value?.startDate
        } to ${Summary.startEndDate.value?.endDate}.
            You are going to travel in a group of ${childrensCount + adultsCount
        } people:
            ${adultsCount} Adult${adultsCount > 1 ? "s" : ""
        }:${childrensCount} Children${childrensCount > 1 ? "s" : ""}
            ${childrensCount > 0 ? "of age " + ChildrenAges : null}.
            Your total trip budget is up to ${Summary.budget.value ? Summary.budget.value : ""
        }
            
            You are going on a ${Summary.occasion.value
          ? Summary.occasion.value.length === 1 &&
            Summary.occasion.value[0] === "Other"
            ? "Vacation"
            : Summary.occasion.value
              ? Summary.occasion.value
                .filter((n: any) => {
                  return n !== "Other";
                })
                .map((node: any, index: any) => {
                  return (
                    (index !== 0
                      ? Summary.occasion.value.filter((n: any) => {
                        return n !== "Other";
                      }).length ===
                        index + 1
                        ? " and "
                        : ", "
                      : "") + node
                  );
                })
              : ""
          : ""
        } to enjoy ${Summary.accomodationPreferences.value
          ? Summary.accomodationPreferences.value.map(
            (node: any, index: any) => {
              return (
                (index !== 0
                  ? Summary.accomodationPreferences.value.length === index + 1
                    ? " and "
                    : ", "
                  : "") + node
              );
            }
          )
          : ""
        }. You prefer ${Summary.stayRequirements.value
          ? Summary.stayRequirements.value.map((node: any, index: any) => {
            return (
              (index !== 0
                ? Summary.stayRequirements.value.length === index + 1
                  ? " and "
                  : ", "
                : "") + node
            );
          })
          : ""
        }. You want to have ${Summary.interests.value
          ? Summary.interests.value.map((node: any, index: any) => {
            return (
              (index !== 0
                ? Summary.interests.value.length === index + 1
                  ? " and "
                  : ", "
                : "") + node
            );
          })
          : ""
        }
            
            Your email id is ${Summary.email.value} and your Phone Number is ${Summary.phone.value
        }`;
      let Arraangement: any = [];
      roomArraangement.map((node: any) => {
        Arraangement.push({
          number: node.room_no,
          adults: node.adults,
          children: node.childrens,
          childrenAge: [
            node.childOneAge,
            node.childTwoAge,
            node.childThreeAge,
            node.childFourAge,
          ],
        });
      });
      errorHandle({
        variables: {
          answerJson: JSON.stringify({
            name: Summary.name.value,
            phone: Summary.phone.value,
            email: Summary.email.value,
            from: Summary.from.value,
            destination: Summary.destination.value,
            startDate: questiontype === 'Cruise' ? cruiseStartDate : Summary.startEndDate.value?.startDate,
            endDate: questiontype === 'Cruise' ? cruiseEndDate : Summary.startEndDate.value?.endDate,
            adultsCount: adultsCount,
            questiontype: questiontype,
            budget: Summary.budget.value[0],
            vacationType: Summary.occasion.value,
            submitTime: new Date().toISOString(),
            roomRequirement: Summary.roomPreference.value,
            resortRquirements: Summary.accomodationPreferences.value,
            previouslyStayedResortInfo:
              Summary.previousHotels.value.length === 1 &&
                Summary.previousHotels.value[0] === "Other"
                ? "Family Vacation"
                : Summary.previousHotels.value,
            kidsCount: childrensCount,
            interests: Summary.interests.value,
            importantInfo: Summary.importanInfo.value,
            comment: Summary.comment.value,
            bookingItems: Summary.bookingHelp.value,
            roomInfo: Arraangement,
            amenities: Summary.amenities.value,
            tripDateFelxibility: flexibleDate,
            summary: summary,
          }),
          errorJson: JSON.stringify(error),
        },
      });
    },
  });
  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [window.innerWidth]);
  const updateSize = () => {
    if (window.innerWidth <= 752) {
      setVertical(false);
    } else {
      setVertical(true);
    }
  };

  const submitData = async () => {
    if (!submitAPIFlag) {
      setSubmitAPIFlag(true);
      let Arraangement: any = [];
      roomArraangement.map((node: any) => {
        let data = [
          node.childOneAge,
          node.childTwoAge,
          node.childThreeAge,
          node.childFourAge,
        ];
        data = data.filter((val) => val !== -1);
        Arraangement.push({
          number: node.room_no,
          adults: node.adults,
          children: node.childrens,
          childrenAge: data,
        });
      });
      var ChildrenAges = "";
      if (childrensCount > 0) {
        for (var i = 0; i < roomArraangement.length; i++) {
          if (roomArraangement[i].childOneAge >= 0) {
            if (ChildrenAges !== "") {
              ChildrenAges += ", ";
            }
            ChildrenAges += roomArraangement[i].childOneAge.toString();
            // console.log("ChildrenAges 1111111",roomArraangement[i].childOneAge,ChildrenAges);
          }
          // console.log("ChildrenAges",roomArraangement[i].childTwoAge, ChildrenAges);
          if (roomArraangement[i].childTwoAge >= 0) {
            if (ChildrenAges !== "") {
              ChildrenAges += ", ";
            }
            ChildrenAges += roomArraangement[i].childTwoAge.toString();
          }
          // console.log("ChildrenAges",roomArraangement[i].childThreeAge, ChildrenAges);
          if (roomArraangement[i].childThreeAge >= 0) {
            if (ChildrenAges !== "") {
              ChildrenAges += ", ";
            }
            ChildrenAges += roomArraangement[i].childThreeAge.toString();
          }
          if (roomArraangement[i].childThreeAge >= 0) {
            if (ChildrenAges !== "") {
              ChildrenAges += ", ";
            }
            ChildrenAges += roomArraangement[i].childFourAge.toString();
          }
          // console.log("ChildrenAges",ChildrenAges);
        }
      }
      // console.log("ChildrenAges",ChildrenAges);
      summary = `${Summary.name.value ? Summary.name.value : ""
        },${questiontype === 'Cruise' ? 'your desired destinations are ' : 'You are going to the'}${questiontype === 'Cruise' ? Summary.cruiseDestinations.value ? Summary.cruiseDestinations.value.map(
          (node: any, index: any) => {
            return (
              (index !== 0
                ? Summary.cruiseDestinations.value.length === index + 1
                  ? " and "
                  : " , "
                : "") + node
            );
          }
        ) : " " : Summary.destination.value ? Summary.destination.value : ""
        } ${questiontype === 'Cruise' ? ' .Cruise Lines ' : 'from '}
         ${questiontype === 'Cruise' ? Summary.preferredCruiseLines.value ?
          Summary.preferredCruiseLines.value.map(
            (node: any, index: any) => {
              return (
                (index !== 0
                  ? Summary.preferredCruiseLines.value.length === index + 1
                    ? " and "
                    : " , "
                  : "") + node
              )
            }
          ) : "" : Summary.from.value ? Summary.from.value : ""
        }, ${questiontype === 'Cruise' ? 'Departure Port' : 'during the dates from'} ${questiontype === 'Cruise' ? Summary.destination.value ? Summary.destination.value : "" : Summary.startEndDate.value?.startDate
        } ${questiontype === 'Cruise' ? ' ,month' : 'to'} ${questiontype === 'Cruise' ? Summary.desiredCuriseMonth.value ? date[0] === date[1] ? date[0] : Summary.desiredCuriseMonth.value : currentMonth : Summary.startEndDate.value?.endDate}
        ${questiontype === 'Cruise' && ',length'} ${questiontype === 'Cruise' ? Summary.desiredCruiseLength.value : ""}.
            You are going to travel in a group of ${childrensCount + adultsCount
        } people:
            ${adultsCount} Adult${adultsCount > 1 ? "s" : ""
        } , ${childrensCount} Children${childrensCount > 1 ? "s" : ""}
            ${childrensCount > 0 ? "of age " + ChildrenAges : ""}.
            Your total trip budget is up to ${Summary.budget.value ? Summary.budget.value : ""
        }
            
           ${questiontype !== 'Cruise' ? ' You are going on a' : ""} ${questiontype !== 'Cruise' ? Summary.occasion.value
          ? Summary.occasion.value.length === 1 &&
            Summary.occasion.value[0] === "Other"
            ? "Vacation"
            : Summary.occasion.value
              ? Summary.occasion.value
                .filter((n: any) => {
                  return n !== "Other";
                })
                .map((node: any, index: any) => {
                  return (
                    (index !== 0
                      ? Summary.occasion.value.filter((n: any) => {
                        return n !== "Other";
                      }).length ===
                        index + 1
                        ? " and "
                        : ", "
                      : "") + node
                  );
                })
              : ""
          : " " : ""
        } ${questiontype !== 'Cruise' ? ' to enjoy' : ""} ${questiontype !== 'Cruise' ? Summary.accomodationPreferences.value
          ? Summary.accomodationPreferences.value.map(
            (node: any, index: any) => {
              return (
                (index !== 0
                  ? Summary.accomodationPreferences.value.length === index + 1
                    ? " and "
                    : ", "
                  : "") + node
              );
            }
          )
          : "" : ""
        }. ${questiontype === 'Cruise' ? ' Your cabin preferrence' : 'You prefer'} ${questiontype === 'Cruise' ? Summary.roomPreference.value ? Summary.roomPreference.value.map(
          (node: any, index: any) => {
            return (
              (index !== 0
                ? Summary.roomPreference.value
                  .length ===
                  index + 1
                  ? " and "
                  : ", "
                : "") + node
            )
          }
        ) : "" : Summary.stayRequirements.value
          ? Summary.stayRequirements.value.map((node: any, index: any) => {
            return (
              (index !== 0
                ? Summary.stayRequirements.value.length === index + 1
                  ? " and "
                  : ", "
                : "") + node
            );
          })
          : " "
        }
            
           . Your email id is ${Summary.email.value} and your Phone Number is ${Summary.phone.value
        }`;
      console.log("ChildrenAges", Summary.cruiseDestinations.value);

      const firstMiddleLastNames = Summary.name.value.split(' ');

      var firstName = '';
      var lastName = '';

      if (firstMiddleLastNames.length === 3) {
        firstName = firstMiddleLastNames[0] + ' ' + firstMiddleLastNames[1];
        lastName = firstMiddleLastNames[2];
      }
      else if (firstMiddleLastNames.length === 2) {
        firstName = firstMiddleLastNames[0];
        lastName = firstMiddleLastNames[1];
      }
      else {
        lastName = firstMiddleLastNames[0];
      }

      updateData({
        variables: {
          key: uuidv4(),
          name: Summary.name.value,
          phone: Summary.phone.value,
          email: Summary.email.value,
          refCode: RefCode,
          from: Summary.from.value,
          destination: Summary.destination.value || dist.current,
          startDate: questiontype === 'Cruise' ? cruiseStartDate : Summary.startEndDate.value?.startDate,
          endDate: questiontype === 'Cruise' ? cruiseEndDate : Summary.startEndDate.value?.endDate,
          adultsCount: adultsCount,
          cities: Summary.cities.value,
          tours: Summary.tours.value,
          attractions: Summary.attractions.value,
          budget: Summary.budget.value[0],
          vacationType: Summary.occasion.value,
          submitTime: new Date().toISOString(),
          roomRequirement: Summary.roomPreference.value,
          questionType: questiontype,
          resortRquirements: Summary.accomodationPreferences.value,
          previouslyStayedResortInfo:
            Summary.previousHotels.value.length === 1 &&
              Summary.previousHotels.value[0] === "Other"
              ? "Family Vacation"
              : Summary.previousHotels.value,
          kidsCount: childrensCount,
          stayRequirements: Summary.stayRequirements.value,
          bookingItems: Summary.bookingHelp.value,
          interests: Summary.interests.value,
          importantInfo: Summary.importanInfo.value,
          comment: Summary.comment.value,
          roomInfo: Arraangement,
          amenities: Summary.amenities.value,
          tripDateFelxibility: flexibleDate,
          cruiseDestinations: Summary.cruiseDestinations?.value,
          preferredCruiseLines: Summary.preferredCruiseLines?.value,
          shipPreference: Summary.shipPreference.value,
          desiredCruiseLength: Summary.desiredCruiseLength.value[0],
          desiredCuriseMonth: Summary.desiredCuriseMonth.value ? date[0] === date[1] ? date[0] : Summary.desiredCuriseMonth.value : currentMonth,
          cruiseLengthComment: Summary.cruiseLengthComment.value,
          summary: summary,
        },
      })
        .then((response: any) => {
          console.log("responseresponseresponse", response);
          if (response.errors === undefined) {
            setSuccess(true);
            SendEmail()

          } else {
            setSuccess(false);
          }
          if (response.errors === undefined) {
            toast.success("Submitted Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something went Wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .finally(() => {
          setSubmitStatus(true);

          setSubmitAPIFlag(false);
        })
        .catch((e: any) => {
          setSuccess(false);
          // ERROR_HANDLE
        });
      // setSubmitStatus(false);
      // setSubmitAPIFlag(false);
    }
  };
  const setNextDisplay = (id: any) => {
    const index = Questions.findIndex((data: { id: any }) => data.id === id);
    // console.log("1234567890", Questions[index]?.hide);
    // if (Questions[index]?.hide === 0) {
    //     id = Questions[index+1].id;
    // }
    setDisplayActiveId(id);
    if (Questions[index]) {
      setCurrentStep(
        category.findIndex(
          (data: { name: any }) => data.name === Questions[index].questionGroup
        )
      );
    }
  };
  const moveToBack = (index: any) => {
    if (index >= 2) {
      let nIndx = index - 2;
      if (
        index === Questions.length &&
        !Questions[index - 1].nextQuestion &&
        showSummery
      ) {
        nIndx = index - 1;
        setShowSummery(false);
      }
      console.log("123456789012", Questions[nIndx]);
      if (Questions[nIndx].hide === 0) {
        nIndx--;
      }
      setDisplayActiveId(Questions[nIndx].id);
      setCurrentStep(
        category.findIndex(
          (data: { name: any }) => data.name === Questions[nIndx].questionGroup
        )
      );
      setProgressBar(progressBar - 7.142857143);
    }
  };

  const updateDOM = async (xinitData: any) => {
    if (xinitData) {
      // @ts-ignore
      const dupl = Questions.filter(
        (node: { id: any }) => node.id === xinitData?.queryTravelQuestion[0].id
      );
      if (
        Questions &&
        dupl.length === 0 &&
        xinitData?.queryTravelQuestion.length >= 1
      ) {
        if (category && currentStep != -1) {
          // @ts-ignore
          setCurrentStep(
            category.findIndex(
              (data: { name: any }) =>
                data.name === xinitData?.queryTravelQuestion[0].questionGroup
            )
          );
        }
        let HideFlag: any = 1;
        console.log(
          "1234567890",
          airSelectedStatus,
          xinitData?.queryTravelQuestion[0].key
        );
        if (
          xinitData?.queryTravelQuestion[0].key === "departureAirport" &&
          !airSelectedStatus
        ) {
          let dd = xinitData?.queryTravelQuestion[0];
          HideFlag = 0;
          console.log("1234567890", airSelectedStatus, dd);
          if (dd.nextQuestion.id && Summary.destination.value !== "") {
            console.log("1234567890 FLAG");
            setNextDisplay(dd.nextQuestion.id);
            setNextNodeID(dd.nextQuestion.id);
            setFlagQue(true);
          }
        } else {
          // @ts-ignore
          setNextNodeID(xinitData?.queryTravelQuestion[0].id);
          // @ts-ignore
          setDisplayActiveId(xinitData?.queryTravelQuestion[0].id);
        }
        // @ts-ignore
        await dispatch(
          Actions.appendQuestion(
            xinitData?.queryTravelQuestion,
            Questions[Questions.length - 1],
            HideFlag
          )
        );
      }
    }
  };
  const updateCategory = async () => {
    if (Category) {
      setCategory(Category?.__type?.enumValues);
    }
  };
  const handleGotoStep = (node: any, key: number) => {
    const grp: any = Questions.filter(
      (data: { questionGroup: any }) => data.questionGroup === node.name
    );
    if (grp.length >= 1) {
      setCurrentStep(key);
      setShowSummery(false);
      if (ParamLocation != "" && key === 0) {
        if (grp[1]) {
          setDisplayActiveId(grp[1].id);
        }
      } else {
        setDisplayActiveId(grp[0].id);
      }
    } else if (category.length - 1 === key && readyToShowSummery) {
      setCurrentStep(category.length - 1);
      setShowSummery(true);
    }
  };

  const getStart = () => {
    if (Questions[0]?.nextQuestion) {
      console.log("nextQuestion", Summary.destination.value);
      if (Questions[0].nextQuestion.id && Summary.destination.value !== "") {
        setNextDisplay(Questions[0].nextQuestion.id);
        setNextNodeID(Questions[0].nextQuestion.id);
        setFlagQue(true);
        console.log(Questions[0]);
      }
    }
    setCurrentStep(0);
  };
  useEffect(() => {
    updateDOM(initData);
  }, [initData]);

  const dist = useRef();

  const onMessage = (event: any) => {
    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    //if (event.origin !== "http://example.com") return;

    // debugger;
    // if (event.data.xsearch) {
    //   alert(`You are going to ${event.data.xsearch}`);
    // }
    console.log("event.dataevent.data", event.data.xsearch);
    if (event.data.xtype) {
      if (event.data.xtype === "bodyMainBeachVacation") {
        setQuestionType("BeachVacation");
      }
      else if (event.data.xtype === "bodyMainWorldExpo") {
        setQuestionType("WorldExploration");
      }
      else if (event.data.xtype === "bodyMainCruise") {
        setQuestionType("Cruise");
      }
    }

    dist.current = event.data.xsearch;
    // Summary.destination.value = event.data.xsearch;
    // dispatch(Actions.setDesitnationThroughParams(event.data.xsearch));
    // dispatch(Actions.setSummary(Summary));
    // event.source is popup
    // event.data is "hi there yourself! the secret response is: rheeeeet!"
  }

  useEffect(() => {
    window.addEventListener("message", onMessage, {
      once: false,
      passive: true,
      capture: true
    });

    return () => window.removeEventListener("message", onMessage);
  }, []);

  useEffect(() => {
    dispatch(Actions.setDesitnationThroughParams(dist.current));
    Summary.destination.value = dist.current;
    dispatch(Actions.setSummary(Summary));
  }, [dist.current]);

  useEffect(() => {
    updateCategory();
    //dispatch(Actions.findLocation());
    // submitData();
  }, [Category]);
  let index = 0;
  return (
    <>
    <div style={{float: 'right', paddingRight: '50px', opacity: '20%'}}>{questiontype}</div>
    <div style={{ height: '100%', backgroundColor: window.location.search?.includes("refCode") ? "#eff2f5" : "white" }}>
      {/* <div className="mainHeaderBG"></div>
            <div className="mainLogo"></div> */}
      <ToastContainer theme="colored" />
      {submitStatus ? (
        success ? (
          <div className="thankscenter">
            <img className="thanksImg" src={thankyou}></img>
            <div className="thanks">Congratulation!</div>
            <div className="thanksinfo">
              Your trip details have been successfully submitted{" "}
            </div>

            {refUserProfile && <div>You were referred by {refUserProfile.firstName}{' '}{refUserProfile.lastName}</div>}
          </div>
        ) : (
          <div className="thankscenter">
            <img className="thanksImg" src={errorimg}></img>
            <div className="thanks">Error!</div>
            <div className="thanksinfo">Please try again later.</div>
          </div>
        )
      ) : (
        <div className="center Box overflowy">
            <div className={vertical ? "left" : "hide"}>
            {/* <Steps current={currentStep} vertical={vertical}>
                            {
                                category ? category.map((node: any, key: any) => {
                                    return <Steps.Item onClick={() => handleGotoStep(node, key)} key={key} className="Step" title={node.name.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")} />
                                }) : null
                            } */}
            {/* <Steps.Item className="Step" onClick={() => setCurrentStep(0)} title="Contact Info" />
                        <Steps.Item className="Step" onClick={() => setCurrentStep(1)} title="Basic Trip Details" />
                        <Steps.Item className="Step" onClick={() => setCurrentStep(2)} title="Flight" />
                        <Steps.Item className="Step" onClick={() => setCurrentStep(3)} title="Accommodations" />
                        <Steps.Item className="Step" onClick={() => setCurrentStep(4)} title="Occasion" />
                        <Steps.Item className="Step" onClick={() => setCurrentStep(5)} title="Interests" />
                        <Steps.Item className="Step" onClick={() => setCurrentStep(6)} title="Summary" /> */}
            {/* </Steps> */}
          </div>

          <div className={vertical ? "right" : "full"} style={{ backgroundColor: window.location.search?.includes("refCode") ? "#eff2f5" : 'white' }}>
            {currentStep < 0 ? (
              <div className="head">
                <div className="header">
                  {/* <div className="subLog"></div> */}
                  <div className="headTitle">
                    <div className="subhead">Trip Planner</div>
                    Share your travel preferences so that a trip designer with
                    intimate knowledge of your destination can plan your next
                    getaway
                  </div>
                  <span onClick={() => getStart()} className="NextButton">
                    Get Started
                  </span>
                </div>
              </div>

            ) : (
              <div>
                {!showSummery && currentStep >= 0 ? (
                  <div className="progressbar">
                    <div className="headerprogress">
                      {parseInt(progressBar)}%
                    </div>
                    <div>
                      <ProgressBar
                        completed={progressBar}
                        bgColor="rgb(0, 157, 255)"
                        borderRadius="8px"
                        width="100%"
                        isLabelVisible={false}
                        height="10px"
                        maxCompleted={100}
                      />
                    </div>
                  </div>
                ) : null}

                {/* {loading ? "T" : "F"} */}
                <div className={loading ? "loaderMain " : "displayNone"}>
                  <div className="loader"></div>
                </div>

                {Questions
                  ? Questions.map((node: any, key: any) => {
                    console.log("hii", node);

                    index++;
                    return displayActiveId === node.id ? (
                      <div key={key}>
                        <NestedNode
                          key={key}
                          index={index}
                          moveToBack={moveToBack}
                          setFlagQue={setFlagQue}
                          loading={loading}
                          Questions={Questions}
                          QuestionsLength={Questions.length}
                          displayActiveId={displayActiveId}
                          setDisplayActiveId={setDisplayActiveId}
                          setNextDisplay={setNextDisplay}
                          nextNodeID={nextNodeID}
                          ageGroup={ageGroup}
                          airSelectedStatus={airSelectedStatus}
                          setAirSelectedStatus={setAirSelectedStatus}
                          setDateFlexFlag={setDateFlexFlag}
                          dateFlexFlag={dateFlexFlag}
                          setFlexibleDate={setFlexibleDate}
                          flexibleDate={flexibleDate}
                          selectionRange={selectionRange}
                          setSelectionRange={setSelectionRange}
                          totalDaysSelected={totalDaysSelected}
                          setTotalDaysSelected={setTotalDaysSelected}
                          setCurrentStep={setCurrentStep}
                          setNextNodeID={setNextNodeID}
                          adultsCount={adultsCount}
                          setAdultsCount={setAdultsCount}
                          childrensCount={childrensCount}
                          setChildrensCount={setChildrensCount}
                          childrensDetails={childrensDetails}
                          setChildrensDetails={setChildrensDetails}
                          Summary={Summary}
                          submitAPIFlag={submitAPIFlag}
                          submitData={submitData}
                          category={category}
                          node={node}
                          toggleHover={toggleHover}
                          setToggleHover={setToggleHover}
                          setRooms={setRooms}
                          showSummery={showSummery}
                          setShowSummery={setShowSummery}
                          roomArraangement={roomArraangement}
                          setRoomArraangement={setRoomArraangement}
                          setReadyToShowSummery={setReadyToShowSummery}
                          rooms={rooms}
                          setProgressBar={setProgressBar}
                          progressBar={progressBar}
                          monthInWords={monthInWords}
                          currentMonth={currentMonth}
                          date={date}

                        ></NestedNode>
                      </div>
                    ) : null;
                  })
                  : null}
              </div>
            )}
            <div className="" ref={messagesEndRef} />
          </div>
        </div>
      )}

      {/* {!submitStatus ?
                <div className={vertical ? "hide" : "show"} >
                    <Steps current={currentStep} vertical={vertical}>
                        {
                            category ? category.map((node: any, key: any) => {
                                return <Steps.Item key={key} onClick={() => handleGotoStep(node, key)} className="Step" />
                            }) : null
                        }
                    </Steps>
                </div>
                : null
            } */}
    </div>
    </>
  );
};
export default TravelQuestions;
